const store = {
    state: {
      user: {
        id: null,
        authority: "",
        login: "",
        logged: false,
        name: "",
      },
    },
  };
  
  export default store;