import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../components/Login";
import Patient from "../components/Patient";
import auth from "@/common/auth";
import store from "@/common/store";


const user = store.state.user;
Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { public: true, isLoginPage: true },
  },
  {
    path: "/",
    redirect: "/patientsToAnnotate",
  },
  {
    path: "/patientsToAnnotate",
    name: "Patient",
    component: Patient,
    meta: { public: false},
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  auth.isAuthenticationChecked.finally(() => {
    const requiresAuth = !to.meta.public;
    const userIsLogged = user.logged;
    if (requiresAuth) {
      if (userIsLogged) {
          next();
      } else {
        if (to.name !== 'Login') 
          next({ name: 'Login' })
        else 
          next()
      }
    } 
    else{
      next()
    }
  });
})

export default router
