import HTTP from "@/common/http";

const RESOURCE_URL = "patient"

export default {
    async getPatientNotAnnotated(id) {
        return (await HTTP.get(RESOURCE_URL+"/"+id)).data;
    },

    async getPatient(id) {
        return (await HTTP.get(RESOURCE_URL+"/review/"+id)).data;
    }  
}