import HTTP from "@/common/http";

const RESOURCE_URL = "patientAssociationR"

export default {
    async create(patientUser) {
        return (await HTTP.post(RESOURCE_URL, patientUser)).data;
    },
    
    async getPatientAnnotation(idUser, idPatient) {
        return (await HTTP.get(RESOURCE_URL+"/data", {
            params:{
                idUser: idUser,
                idPatient: idPatient
            
             }
            })).data;
    },

    async update(patientUser) {
        return (await HTTP.put(RESOURCE_URL+"/update", patientUser)).data;
    },
}