<template>
  <v-app>
    <v-card height="100%"  >
      <v-app-bar dark color="black" v-if="user.logged">
        <v-toolbar-title class="title">
          <router-link to="/recipes" tag="span">Annotation Web</router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="logout()" text>Logout</v-btn>
        </v-toolbar-items>
      </v-app-bar>

      <v-main>
        <v-container fill-height>
          <router-view />
        </v-container>
      </v-main>

    </v-card>
  </v-app>
</template>



<script>
import store from "@/common/store";
import auth from "@/common/auth";

export default {
  name: "App",
  data: () => ({
    user: store.state.user,
  }),
  methods: {
    logout() {
      auth.logout();
      this.$router.push({ name: "Login" });
    },
  }
}
</script>

