<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-container fill-height>
      <v-row class="mt-16" align="center" justify="center">
        <v-col>
          <v-card-title style="font-size:3em">Human In The Loop</v-card-title>
          <v-spacer></v-spacer>
          <v-card-subtitle style="font-size:1em"> Web para la clasificación de pacientes oncologicos para el estudio de posibles avances innovadores </v-card-subtitle>
        </v-col>
        <v-col>
          <v-form @submit.prevent="userLogin" >
            <v-card> 
              <v-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  name="E-Mail"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="username"
                    :error-messages="errors"
                    label="E-Mail"
                    name="login"
                    prepend-icon="mdi-email"
                    type="text"
                    color="grey"
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Contraseña"
                  rules="required"
                >
                  <v-text-field
                    v-model="password"
                    :error-messages="errors"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :type="show ? 'password' : 'text'"
                    color="grey"
                  ></v-text-field>
                </validation-provider>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="invalid"
                  color="primary"
                >
                  Iniciar Sesion
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
     
    </v-container>
  <v-footer
    dark
    padless
    app bottom fixed
  >
    <v-card
      flat
      tile
      class="indigo lighten-1 white--text text-center"
       width="100%"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        Application for annotation.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
      </v-card-text>
    </v-card>
  </v-footer>
  </validation-observer>
</template>

<script>
import auth from "../common/auth";
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("email", {
  ...email,
  message: "Email tiene que ser válido",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
       icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      alignments: [
        'start',
        'center',
        'end',
      ],
      username: "",
      password: "",
      show: true,
    };
  },
  methods: {
    userLogin() {
      auth
        .login({
          login: this.username,
          password: this.password,
        })
        .then(() => {
          // vamos a la página anterior después de autenticarnos
          this.$router.go(-1);
        })
        .catch((err) => {
          console.error(err);
          alert("Error autenticando");
        });
    },
  },
};
</script>
