import axios from "axios";
import auth from "./auth";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL ,
});

const onUnauthorized = () => {
  alert("Acceso denegado!");
  auth.logout();
};
const onResponseSuccess = (response) => response;


const onResponseFailure = (err) => {
  const status = err.response.status;
  // excepto cuando estemos haciendo login
  if (!err.config.url.includes("authenticate")) {
    if (status == 401 || status == 403) {
      onUnauthorized();
    }
  }
  return Promise.reject(err);
};


const onRequest = (config) => {
  const token = auth.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

HTTP.interceptors.response.use(onResponseSuccess, onResponseFailure);
HTTP.interceptors.request.use(onRequest);
export default HTTP;
  