<template>
  <v-app>
    <notifications group="foo" position="top right" />
    <v-main class="grey lighten-3 mt-10">
      <v-container id="app">
        <template>
          <v-expansion-panels v-model="openedPanel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-black">
                 'Patient'
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-for="(value, key, index) in listObjects[0]" :key="key">  
                  <span style="color:Brown">{{ patientHeader[index]}}</span> : "{{ value }}"
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-black">
                'StageEvent'
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-for="(value, key, index) in listObjects[1]" :key="key">  
                  <span style="color:Brown">{{ stageEventHeader[index]}}</span> : "{{ value }}"
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-black">
                'ClinicalData'
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-for="(value, key, index) in listObjects[2]" :key="key">  
                  <span style="color:Brown">{{ clinicalDataHeader[index]}}</span> : "{{ value }}"
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-black">
                'NewTumorEvent'
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-for="(value, key, index) in listObjects[3]" :key="key">  
                  <span style="color:Brown">{{ newTumorHeader[index]}}</span> : "{{ value }}"
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-black">
                'ExternalFactor'
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-for="(value, key, index) in listObjects[4]" :key="key">  
                  <span style="color:Brown">{{ externalFactorHeader[index]}}</span> : "{{ value }}"
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-black">
                'Radiation'
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-for="(value, key, index) in listObjects[5]" :key="key">  
                  <span style="color:Brown">{{ radiationHeader[index]}}</span> : "{{ value }}"
                </p>
              </v-expansion-panel-content>  
            </v-expansion-panel>                          
          </v-expansion-panels>

          <v-form v-model="valid" ref="form"> 
            <div class="px-4 pt-4 text-h6">Treatment to patient</div>
            <v-radio-group  class="ml-6" row v-model="quimio" :rules="[v => !!v || 'You must choose to continue!']"> 
              <v-radio
                label="Chemotherapy"
                value="Chemotherapy"
              ></v-radio>
              <v-radio
                label="No treatment"
                value="No-treatment"
              ></v-radio>
              <v-radio
                label="Undecided"
                value="Unknow"
              ></v-radio>
            </v-radio-group>

            <v-text-field
              class="ml-6"
              v-model="reason"
              label="reason for chemotherapy assignment"
            ></v-text-field>

            <div class="px-4 pt-4 text-h6">¿Synthetic?</div>
            <v-radio-group class="ml-6" row v-model="synthetic" :rules="[v => !!v || 'You must choose to continue!']"> 
              <v-radio
                label= "Yes"
                value= "Yes"
              ></v-radio>
              <v-radio
                label= "No"
                value= "No"
              ></v-radio> 
            </v-radio-group>

            <div class="px-4 pt-4 text-h6">Comment on the case</div>
            <v-col cols="12" >
              <v-textarea
                outlined
                color="teal"
                v-model="comment"
              >
                <template v-slot:label>
                  <div>
                    Anything related to the case that deserves to be mentioned. <small>(optional)</small>
                  </div>
                </template>
              </v-textarea>
            </v-col>

            <v-row>
              <v-col xs="6">
                <v-btn :disabled="reviewId == 0" color="primary" elevation="5" depressed outlined @click="loadPreviousCase" >
                  Previous Case
                </v-btn>
              </v-col>
              <v-col xs="6" class="d-flex justify-end">
                <v-btn :disabled="!valid" color="primary" elevation="5" depressed outlined @click="saveDecision" >
                  Next Case
                </v-btn>
              </v-col>
            </v-row>
            
          </v-form>
          <v-row align="center" justify="center" v-if=loading>
            <v-progress-circular indeterminate color="purple"></v-progress-circular>
          </v-row>

        </template>
      </v-container>
    </v-main>
  </v-app>
</template>



<script>
import store from "@/common/store";
import PatientRepository from "@/repositories/PatientRepository";
import PatientAssociationRepository from "@/repositories/PatientAssociationRepository";

export default {
  data() {
    return {
      loading: true,
      valid: false,
      user: store.state.user,

      dataAnnotatedRecord: null,
      patientData: null,

      listObjects: [],
      openedPanel: [0],

      quimio: "",
      synthetic: "",
      reason: "",
      comment: "",

      notAnnotatedPatientId: null,
      reviewId: null,

      //Structure to show data 
      clinicalData: {},
      patient: {},
      stageEvent: {},
      newTumorEvent: {},
      externalFactor: {},
      radiation: {},

      items: [
        {name: 'Patient'},
        {name: 'StageEvent'},
        {name: 'ClinicalData'},
        {name: 'NewTumorEvent'},
        {name: 'ExternalFactor'},
        {name: 'Radiation'}
      ],
      patientHeader: ["Gender", "Days To Birth", "Race", "Ethnicity", "Other Dx", "History Of Neoadjuvant Treatment",
                "Days To Initial Pathologic Diagnosis", "Age At Initial Pathologic Diagnosis", "Initial Pathologic Diagnosis Method",
                "Initial Pathology Dx Method Other","Surgery Performed Type","Primary Lymph Node Presentation Assessment", "Lymph Node Examined Count",
                "Number Of Lymph Nodes Positive By He", "Number Of Lymph Nodes Positive By Ihc", "Neoplasm Histologic Grade",
                "Histologic Grading Tier Category", "Maximum Tumor Dimension", "Residual Tumor"],
      stageEventHeader: ["System Version", "Pathologic Stage", "Pathologic_T", "Pathologic_N", "Pathologic_M"],
      clinicalDataHeader: ["Tissue Source Site", "ICD_O_3 Site", "ICD_O_3 Histology", "ICD_10", "Tumor Tissue Site",
                "Adenocarcinoma Invasion", "Histological Type", "Histological Type Other", "Tumor Type", "Anatomic Neoplasm Subdivision",
                "Anatomic Neoplasm Subdivision Other", "Tissue Prospective Collection Indicator", "Tissue Retrospective Collection Indicator"],
      newTumorHeader: ["Person Neoplasm Cancer Status", "New Tumor Event After Initial Treatment", "Days To New Tumor Event After Initial Treatment",
                "New Neoplasm Event Type", "New Neoplasm Event Occurrence Anatomic Site", "New Neoplasm Occurrence Anatomic Site Text", "Progression Determined By",
                "New Tumor Event Additional Surgery Procedure", "Days To New Tumor Event Additional Surgery Procedure", "Residual Disease Post New Tumor Event Margin Status",
                "Additional Radiation Therapy", "Additional Pharmaceutical Therapy"],
      externalFactorHeader: ["Tobacco Smoking History", "Year Of Tobacco Smoking Onset", "Stopped Smoking Year", "Number Pack Years Smoked",
                "Alcohol History Documented", "Alcoholic Exposure Category", "Frequency Of Alcohol Consumption", "Amount Of Alcohol Consumption Per Day",
                "History Of Diabetes", "Days To Diabetes Onset", "History Of Chronic Pancreatitis", "Days To Pancreatitis Onset", "Family History Of Cancer",
                "Relative Cancer Type"],
      radiationHeader: ["Radiation Therapy", "Postoperative Rx Tx",  "History Prior Surgery Type Other",  "Primary Therapy Outcome Success"],

    }
  },
  
  created() {
    this.getNewCaseToAnnotate();
  },
  
  methods: {
    getNewCaseToAnnotate() {
      PatientRepository.getPatientNotAnnotated(this.user.id).then((data) => 
      {this.patientData = data;
      this.notAnnotatedPatientId = this.patientData.id;
      this.reviewId = this.patientData.id;
      this.loadData();
      this.loadObjects()}).finally(this.loading = false).catch((err) => 
      {if (err.response.status == 404){
        this.$notify({
          group: "foo",
          title: "Info",
          text: "No more data to annotate in this moment. Try later."
        }, 4000)
      }
      })
    },

    loadData(){
      this.$set(this,"clinicalData", this.patientData.clinicalData)
      this.patient = this.patientData.patientDetails;
      this.stageEvent = this.patientData.stageEvent;
      this.newTumorEvent = this.patientData.newTumorEvent;
      this.externalFactor = this.patientData.externalFactor;
      this.radiation = this.patientData.radiation;
    },

    loadObjects(){
      this.listObjects.push(this.patient);
      this.listObjects.push(this.stageEvent);
      this.listObjects.push(this.clinicalData);
      this.listObjects.push(this.newTumorEvent);
      this.listObjects.push(this.externalFactor);
      this.listObjects.push(this.radiation);
    },

    saveDecision(){
      this.loading = true;
      this.patientData.quimio = this.quimio;
      this.patientData.synthetic = this.synthetic;
      this.patientData.reason = this.reason;
      this.patientData.comment = this.comment;
      console.log(this.notAnnotatedPatientId);
      console.log(this.reviewId);
      if (this.reviewId == this.notAnnotatedPatientId){
        this.createAnnotation()
      }
      else{ 
        this.updateAnnotation()
      }
    },

    createAnnotation(){
      var patientUser = {
        idUser : this.user.id,
        idPatient : this.patientData.id,
        quimio : this.patientData.quimio,
        isRealCase : this.patientData.synthetic,
        reason : this.patientData.reason,
        comment : this.patientData.comment
      }
      PatientAssociationRepository.create(patientUser).then(this.loadNextPatient).finally(this.loading = false).catch((err) => console.log(err));
    },

    updateAnnotation(){
      var patientUser = {
        idUser : this.user.id,
        idPatient : this.patientData.id,
        quimio : this.patientData.quimio,
        isRealCase : this.patientData.synthetic,
        reason : this.patientData.reason,
        comment : this.patientData.comment,
        dateOfAnnotation	: this.patientData.dateOfAnnotation
      }
      PatientAssociationRepository.update(patientUser).then(this.loadNextPatient).finally(this.loading = false).catch((err) => console.log(err));
    },

    loadNextPatient(){
      this.openedPanel = [0];
      this.listObjects = [];
      if ((this.reviewId == this.notAnnotatedPatientId)||(this.reviewId == this.notAnnotatedPatientId - 1)){
        this.getNewCaseToAnnotate();
        this.$refs.form.reset()
      }
      else{ 
        this.reviewId = this.reviewId + 1;
        this.getCaseAlredyAnnotated();
        this.getPatientAnnotation();
      }
    },

    getCaseAlredyAnnotated() {
      PatientRepository.getPatient(this.reviewId).then((data) => 
      {this.patientData = data;
      this.loadData();
      this.loadObjects()}).finally(this.loading = false).catch((err) => console.log(err))
    },

    loadPreviousCase(){
      this.openedPanel = [0];
      this.listObjects = [];
      this.reviewId = this.reviewId - 1;
      this.getCaseAlredyAnnotated();
      this.getPatientAnnotation();
    },

    getPatientAnnotation(){
      PatientAssociationRepository.getPatientAnnotation(this.user.id, this.reviewId).then((data) => 
      {this.dataAnnotatedRecord = data;
      this.quimio = this.dataAnnotatedRecord.quimio;
      this.reason = this.dataAnnotatedRecord.reason;
      this.comment = this.dataAnnotatedRecord.comment;
      this.synthetic = this.dataAnnotatedRecord.isRealCase}).catch((err) => console.log(err));
    }
  }
}
</script>